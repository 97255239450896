export default function initializeBackToTop() {
  const backToTopElement = document.querySelector(".back-to-top");

  window.addEventListener("scroll", () => {
    if (window.scrollY > window.innerHeight * 0.4) {
      backToTopElement.classList.remove("back-to-top--hidden");
      return;
    }

    backToTopElement.classList.add("back-to-top--hidden");
  });

  backToTopElement &&
    backToTopElement.addEventListener("click", () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
}
