export default function initializePopupNewsletter() {
  const popupNewsletter = document.querySelector(".newsletter-popup");
  const popupOverlay = document.querySelector(".newsletter-popup__overlay");
  const closePopupButton = document.querySelector(".newsletter-popup__close-icon");
  const popupRenewal = document.querySelector("[data-popup-renewal]")?.dataset.popupRenewal;

  if (document.cookie.indexOf("popupDisplayed=true") === -1) popupNewsletter?.classList.add("newsletter-popup--active");

  popupNewsletter &&
    popupOverlay.addEventListener("click", () => {
      popupNewsletter.classList.remove("newsletter-popup--active");

      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + parseInt(popupRenewal));
      document.cookie = `popupDisplayed=true; expires=${expirationDate.toUTCString()}; path=/`;
    });

  popupNewsletter &&
    closePopupButton.addEventListener("click", () => {
      popupNewsletter.classList.remove("newsletter-popup--active");

      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + parseInt(popupRenewal));
      document.cookie = `popupDisplayed=true; expires=${expirationDate.toUTCString()}; path=/`;
    });
}
