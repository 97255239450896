import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export default function initializeLatestPosts() {
  if (window.innerWidth <= 1024) {
    new Swiper(".latest-blog-posts__swiper-container", {
      speed: 2000,
      effect: "slide",
      spaceBetween: 30,
      slidesPerView: "auto",
    });
  }
}
