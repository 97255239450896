import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export default function initlializePartners() {
  if (window.innerWidth < 768) {
    new Swiper(".top-product-menu", {
      speed: 2000,
      effect: "slide",
      spaceBetween: 30,
      loop: false,
      slidesPerView: "auto",
      autoplay: {
        delay: 5000,
      },
    });
  }
}
