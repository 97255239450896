import initializeNavBar from "./navbar/navbar.js";
import initializeSliders from "./sliders/index.js";
import initializeAccordion from "./accordion/accordion.js";
import initializeVideoPlayers from "./video/index.js";
import initializePopupNewsletter from "./popup/initializePopupNewsletter.js";
import initializeInfoBoxes from "./infobox/initializeInfoBoxes.js";
import initializeBackToTop from "./back-to-top/backToTop.js";

document.addEventListener("DOMContentLoaded", function () {
  const mobileMenu = document.querySelector(".site-header__mobile-menu");
  const headerNavbar = document.querySelector(".site-header__header-navbar");
  const faqElements = document.querySelector(".faq__container");
  const languagesMenu = document.querySelector(".site-header__languages");

  initializeNavBar(mobileMenu, headerNavbar, languagesMenu);
  initializePopupNewsletter();
  initializeAccordion(faqElements);
  initializeSliders();
  initializeVideoPlayers();
  initializeInfoBoxes();
  initializeBackToTop();
});
