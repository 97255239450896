import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export default function initializeOverNav() {
  if (window.innerWidth <= 767) {
    new Swiper(".over-nav__swiper", {
      slidesPerView: "auto",
      freeMode: true,
      mousewheel: {
        releaseOnEdges: true,
      },
    });
  }
}
