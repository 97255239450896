export default function initializeHomepageVideo() {
  const videoWrapper = document.querySelector(".homepage-header__video-wrapper");
  const videoPlayer = document.querySelector(".video-player");
  const videoOverflow = document.querySelector(".video-player__overflow");
  const videoClose = document.querySelector(".video-player__close");
  const videoElement = document.querySelector(".video-player__player");

  videoWrapper && videoWrapper.addEventListener("click", () => videoPlayer.classList.add("video-player--active"));

  videoOverflow &&
    videoOverflow.addEventListener("click", () => {
      videoPlayer.classList.remove("video-player--active");
      videoElement.pause();
    });

  videoClose &&
    videoClose.addEventListener("click", () => {
      videoPlayer.classList.remove("video-player--active");
      videoElement.pause();
    });
}
