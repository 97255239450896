export default function initializeNavBar(mobileMenu, headerNavbar, languagesMenu) {
  mobileMenu &&
    mobileMenu.addEventListener("click", () => {
      const hamburgerIcon = document.querySelector(".site-header__hamburger");
      headerNavbar.classList.toggle("site-header__header-navbar--active");
      hamburgerIcon.src = headerNavbar.classList.contains("site-header__header-navbar--active")
        ? hamburgerIcon.src.replace("hamburger-menu", "close-menu")
        : hamburgerIcon.src.replace("close-menu", "hamburger-menu");
    });

  languagesMenu && languagesMenu.addEventListener("click", () => languagesMenu.classList.toggle("site-header__languages--active"));
}
