export default function initializeInfoBoxes() {
  const packagesWrapper = document.querySelector(".packages__wrapper");

  packagesWrapper &&
    packagesWrapper.addEventListener("click", (e) => {
      if (e.target.classList.contains("packages__info-icon")) {
        e.target.parentElement.classList.toggle("packages__info-box--active");
        return;
      }

      const activeInfoBoxes = document.querySelectorAll(".packages__info-box--active");
      if (activeInfoBoxes.length) {
        activeInfoBoxes.forEach((box) => {
          box.classList.remove("packages__info-box--active");
        });
      }
    });
}
