import initializeLatestPosts from "./initializeLatestPosts.js";
import initializeHeaderTestimonials from "./initializeHeaderTestimonials.js";
import initializeBlockTestimonials from "./initializeBlockTestimonials.js";
import initlializePartners from "./initializePartners.js";
import initializeProjects from "./initializeProjects.js";
import initializeProductsTabs from "./initializeProductsTabs.js";
import initializeOverNav from "./initializeOverNav.js";

export default function initializeSliders() {
  initializeLatestPosts();
  initializeBlockTestimonials();
  initializeHeaderTestimonials();
  initlializePartners();
  initializeProjects();
  initializeProductsTabs();
  initializeOverNav();
}
