import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export default function initializeBlockTestimonials() {
  new Swiper(".block__testimonials", {
    speed: 2000,
    effect: "slide",
    slidesPerView: 3,
    loop: true,
    autoplay: {
      delay: 5000,
    },
    pagination: {
      el: ".block_testimonials__pagination",
      clickable: true,
    },
  });
}
