export default function initializeAccordion(el) {
  el &&
    el.addEventListener("click", function (event) {
      if (event.target.closest(".faq__item")) {
        const item = event.target.closest(".faq__item");
        const itemIcon = item?.querySelector(".faq__item-icon-arrow");

        const allItems = el.querySelectorAll(".faq__item");
        allItems.forEach(function (el) {
          if (el !== item && el.classList.contains("faq__item--active")) {
            el.classList.remove("faq__item--active");
            el?.querySelector(".faq__item-icon-arrow").classList.remove("ilabs-primary-bg-color");
          }
        });

        if (!item.classList.contains("faq__item--active")) {
          item.classList.add("faq__item--active");
          itemIcon.classList.add("ilabs-primary-bg-color");
          return;
        }

        item.classList.remove("faq__item--active");
        itemIcon.classList.remove("ilabs-primary-bg-color");
      }
    });
}
